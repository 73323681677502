<template>
  <div>
    <!-- Header Slide Section -->
    <div
      class="header-slide mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="caption">
        <div class="box">
          <div class="container">
            <div class="inside-box py-4">
              <h1>Take a look around Amchara Somerset</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="container my-5">
      <div class="row text-center">
        <div class="col-md-10 mx-auto">
          <h2 style="color: #83c550">
            A Beautiful Retreat in the Tranquil Countryside
          </h2>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="container my-5">
      <div class="row">
        <div class="gal">
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-1.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-2.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-3.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-4.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-5.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-6.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-7.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-8.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="@/assets/images/uk-retreat/full-gallery/amchara-retreat-somerset-aerial-view.png"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-9.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-10.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-11.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-12.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-13.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-14.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-15.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-16.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />

          <!--<img src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-24-small.jpg" alt="Amchara Health Retreat Somerset, UK">-->
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-18.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-23.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-19.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-20.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <!--<img src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-21.jpg" alt="Amchara Health Retreat Somerset, UK">-->
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-22.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
          <!--<img src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-25.jpg" alt="Amchara Health Retreat Somerset, UK">-->
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/uk-img-17.jpg"
            alt="Amchara Health Retreat Somerset, UK"
          />
        </div>
      </div>
      <router-link
        href="/enquiries"
        class="btn btn-orange-gradient btn-block mt-3"
        >ENQUIRE NOW</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      bgImages: [require("@/assets/images/header/uk-gallery-header.jpg")],
    };
  },
};
</script>

<style>
</style>